import React, { useState, useEffect, useRef } from "react";
import { useAuth } from "../contexts/AuthContext";
import styles from "./MovieCard.module.css";
import StarRating from "./StarRating"; // same half-star capable component

const MovieCard = ({ movie, userRating, onUpdateRating, isAdmin, onRevealStatusChange }) => {
    const { user } = useAuth();
    const [isExpanded, setIsExpanded] = useState(false);
    const [originalCardRect, setOriginalCardRect] = useState(null);
    const [popupStyle, setPopupStyle] = useState({});
    const [isReviewing, setIsReviewing] = useState(false);

    const cardRef = useRef(null);

    // Find the user’s existing score if any
    const userScore = movie.scores.find((score) => score.user_id === user?.id)?.score || 0;

    // When the user clicks a half/full star in StarRating
    const handleRatingChange = (newRating) => {
        if (!user) {
            console.error("No user data available.");
            return;
        }
        onUpdateRating(movie.id, newRating, user.id);

        // If already revealed, we can close the rating block if desired
        if (movie.is_revealed) {
            setIsReviewing(false);
        }
    };

    // Expand the card into a popup
    const handleExpand = (event) => {
        event.stopPropagation();
        const rect = cardRef.current.getBoundingClientRect();
        setOriginalCardRect(rect);
        setPopupStyle({
            position: "fixed",
            top: rect.top + "px",
            left: rect.left + "px",
            width: rect.width + "px",
            height: rect.height + "px",
            transform: "translate(0, 0)",
            opacity: 0,
        });
        setIsExpanded(true);
    };

    // Animate popup on isExpanded change (original logic)
    useEffect(() => {
        if (isExpanded) {
            // Prevent background scroll
            const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
            document.body.style.overflow = "hidden";
            document.body.style.paddingRight = `${scrollbarWidth}px`;

            // Fade out the underlying card
            cardRef.current.classList.add(styles.fadeOut);

            // Animate the popup open
            requestAnimationFrame(() => {
                setPopupStyle((prevStyle) => ({
                    ...prevStyle,
                    top: "50%",
                    left: "50%",
                    width: "80vw",
                    height: "80vh",
                    transform: "translate(-50%, -50%)",
                    opacity: 1,
                }));
            });
        } else {
            // Restore scroll
            document.body.style.overflow = "";
            document.body.style.paddingRight = "";
            if (cardRef.current) {
                cardRef.current.classList.remove(styles.fadeOut);
            }
        }
    }, [isExpanded]);

    // Close the popup
    const handleClosePopup = (event) => {
        event.stopPropagation();
        if (!originalCardRect) {
            setIsExpanded(false);
            setIsReviewing(false);
            return;
        }
        const rect = originalCardRect;
        setPopupStyle((prev) => ({
            ...prev,
            top: rect.top + "px",
            left: rect.left + "px",
            width: rect.width + "px",
            height: rect.height + "px",
            transform: "translate(0, 0)",
            opacity: 0,
        }));
        setTimeout(() => {
            setIsExpanded(false);
            setIsReviewing(false);
        }, 300);
    };

    // Reveal or hide reviewer scores
    const handleRevealScores = (event) => {
        event.stopPropagation();
        const newStatus = movie.is_revealed ? 0 : 1;
        onRevealStatusChange(movie.id, newStatus);
    };

    // Sort scores descending for display
    const sortedScores = [...movie.scores].sort((a, b) => b.score - a.score);

    return (
        <>
            {/* --- The card on the main page (unchanged) --- */}
            <div ref={cardRef} className={`${styles.movieCard} ${isExpanded ? styles.fadeOut : ""}`} onClick={handleExpand}>
                <div className={styles.cardFront}>
                    <img src={movie.poster_url} alt={movie.title} />
                    <h3>{movie.title}</h3>
                    {isAdmin && (
                        <button
                            onClick={(event) => {
                                event.stopPropagation();
                                handleRevealScores(event);
                            }}
                            style={{
                                backgroundColor: movie.is_revealed ? "#dc3545" : "#007bff",
                            }}
                        >
                            {movie.is_revealed ? "Hide Scores" : "Reveal Scores"}
                        </button>
                    )}
                </div>
            </div>

            {/* --- Grey overlay that closes the popup --- */}
            {isExpanded && <div className={`${styles.overlay} ${isExpanded ? styles.active : ""}`} onClick={handleClosePopup} />}

            {/* --- The popup itself --- */}
            {isExpanded && (
                <div className={styles.popup} style={popupStyle} onClick={(e) => e.stopPropagation()}>
                    <div className={styles.cardBack}>
                        <div className={styles.popupLayout}>
                            {/** LEFT column: Poster -> Title -> Description **/}
                            <div className={styles.leftColumn}>
                                <img src={movie.poster_url} alt={movie.title} className={styles.popupPoster} />
                                <h3>
                                    {movie.title} ({movie.year})
                                </h3>
                                <p>{movie.description}</p>
                            </div>

                            {/** RIGHT column: Curtains or reviewer scores + user rating **/}
                            <div className={styles.rightColumn}>
                                {!movie.is_revealed ? (
                                    <div className={styles.curtainsWrapper}>
                                        <div className={styles.theatreCurtainLeft}></div>
                                        <div className={styles.theatreCurtainRight}></div>
                                        <div className={styles.resultsPendingText}>Results Pending</div>
                                    </div>
                                ) : (
                                    <div className={styles.reviewerScores}>
                                        <h3>Reviewers scores:</h3>
                                        <div className={styles.reviewerList}>
                                            {sortedScores.length === 0 ? (
                                                <p>No scores yet</p>
                                            ) : (
                                                sortedScores.map((score, index) => (
                                                    <div key={score.user_id} className={styles.reviewerRow}>
                                                        <span>{score.real_name}</span>
                                                        <StarRating count={score.score} onChange={() => {}} readOnly={true} size={24} />
                                                    </div>
                                                ))
                                            )}
                                        </div>
                                    </div>
                                )}

                                {!movie.is_revealed || isReviewing ? (
                                    <div className={styles.userRatingBlock}>
                                        <h3>Your Score</h3>
                                        <StarRating count={userScore} onChange={handleRatingChange} size={32} readOnly={false} />
                                        {movie.is_revealed && (
                                            <button
                                                className={styles.cancelButton}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setIsReviewing(false);
                                                }}
                                            >
                                                Back
                                            </button>
                                        )}
                                    </div>
                                ) : (
                                    <button
                                        className={styles.reviewButton}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setIsReviewing(true);
                                        }}
                                    >
                                        Review Movie
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default MovieCard;
